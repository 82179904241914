import React, { useState } from 'react';
import './ProductTable.css';
import { Link } from 'react-router-dom';

const ProductTable = ({ products, is_login }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const sortedProducts = React.useMemo(() => {
    if (!sortConfig.key) return products;

    const sorted = [...products].sort((a, b) => {
      const aValue = a.customFields[sortConfig.key] || a[sortConfig.key] || '';
      const bValue = b.customFields[sortConfig.key] || b[sortConfig.key] || '';

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue, undefined, { numeric: true });
      }

      return aValue - bValue;
    });

    return sortConfig.direction === 'asc' ? sorted : sorted.reverse();
  }, [products, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <span className="sort-icon active user-select-none asc">︿</span>
      ) : (
        <span className="sort-icon active user-select-none desc">︿</span>
      );
    }
    return <span className="sort-icon text-muted user-select-none">︿</span>;
  };

  const getCustomerMultiplier = (multiplierGroup) => {
    const userMultiplier = localStorage.getItem('techtop_user_multiplier');
    if (userMultiplier || userMultiplier !== '' && userMultiplier !== null) {
      var multiplierData = JSON.parse(userMultiplier);
      console.log(multiplierData);
      const entry = multiplierData.find(item => item.multiplier_group === multiplierGroup);
      return entry ? entry.customer_multiplier : null; // Return the multiplier or null if not found
    }
  }

  const getNetPrice = (price, multiplierGroup) => {
    var multiplier = getCustomerMultiplier(multiplierGroup);
    return parseFloat(price*multiplier).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
  }

  const renderProductTableData = () => {
    return (
      <div className="table-responsive product-table">
        <table className="table">
          <thead className="sticky-top">
            <tr>
              <th onClick={() => requestSort('num')}>
                Model Number {renderSortIcon('num')}{' '}
              </th>
              <th onClick={() => requestSort('MGN- Product Line')}>
                Motor Group {renderSortIcon('MGN- Product Line')}
              </th>
              <th onClick={() => requestSort('MGN- Frame')}>
                Frame Size {renderSortIcon('MGN- Frame')}
              </th>
              <th onClick={() => requestSort('MGN- HP')}>
                HP {renderSortIcon('MGN- HP')}
              </th>
              <th onClick={() => requestSort('MGN- Pole/RPM')}>
                RPM {renderSortIcon('MGN- Pole/RPM')}
              </th>
              <th onClick={() => requestSort('MGN- Voltage')}>
                Voltage {renderSortIcon('MGN- Voltage')}
              </th>
              <th onClick={() => requestSort('Enclosure')}>
                Enclosure {renderSortIcon('Enclosure')}
              </th>
              <th onClick={() => requestSort('price')}>
                Price {renderSortIcon('price')}
              </th>
              <th onClick={() => requestSort('Multiplier Code')}>
                Multiplier Code {renderSortIcon('Multiplier Code')}
              </th>
              {is_login === 1 ? <th>Net Price</th> : null}
              {is_login === 1 ? <th>Inventory</th> : null}
            </tr>
          </thead>
          <tbody>
            {sortedProducts.length > 0 ? (
              sortedProducts.map((product, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      target="_blank"
                      className="link-with-icon"
                      to={`/motors/${product.num}`}
                    >
                      {product.num}
                    </Link>
                  </td>
                  <td>
                    {product.customFields['MGN- Product Line']
                      ? product.customFields['MGN- Product Line'].replace(
                          /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                          ''
                        )
                      : ''}
                  </td>
                  <td>{product.customFields['MGN- Frame']}</td>
                  <td>
                    {product.customFields['MGN- HP']
                      ? product.customFields['MGN- HP'].replace(
                          /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                          ''
                        )
                      : ''}
                  </td>
                  <td>
                    {product.customFields['MGN- Pole/RPM']
                      ? product.customFields['MGN- Pole/RPM'].replace(
                          /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                          ''
                        )
                      : ''}
                  </td>
                  <td>
                    {product.customFields['MGN- Voltage']
                      ? product.customFields['MGN- Voltage'].replace(
                          /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                          ''
                        )
                      : ''}
                  </td>
                  <td>{product.customFields['Enclosure']}</td>
                  <td className="price">
                    {parseFloat(product.price).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>{product.customFields['Multiplier Code']}</td>
                  {is_login === 1 ? (
                    <td className="inventory">
                      {getNetPrice(product.price,product.customFields['Multiplier Code'])}
                    </td>
                  ) : null}
                  {is_login === 1 ? (
                    <td className="inventory">
                      <Link
                        target="_blank"
                        className="link-with-icon"
                        to={`/motors/${product.num}/inventory`}
                      >
                        inventory
                      </Link>
                    </td>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center font-weight-bold">
                  No products available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return <>{renderProductTableData()}</>;
};

export default ProductTable;
