/*
* @Author: Abhi Bhatt
* @Date:   2020-07-10 18:58:44
* @Last Modified by:   babysoft08
* @Last Modified time: 2024-12-28 14:31:45
*/
import React from 'react';
import {Link,Redirect} from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';
import { Validation } from '../../validation';


//const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class SignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
            errors: {},
            error_message:'',
            signup_error_message:'',
            user_id:'',
            first_name:'',
            last_name:'',
            is_login:0,
            is_logout:0,
            success_redirect:0
		}
		this.renderSignUpForm = this.renderSignUpForm.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.successRedirect = this.successRedirect.bind(this);
		this.userService = new UserService();
	}

	componentDidMount() {

	}

	successRedirect() {
    	if(this.state.success_redirect===1) {
    		return <Redirect to="/" />
    	}
    }

    handleSubmit(e) {
    	e.preventDefault();
        const { fields } = this.state;
        let errors = Validation(fields,['first_name','last_name','company','address','phone','email','password','confirm_password']);
        console.log(errors);
        if (Object.keys(errors).length > 0) {
        	this.setState({errors: errors});
          //setErrors(errors);
          return;
        }
        console.log(errors);
        this.submitForm(e);
    };

	submitForm(e) {
		e.preventDefault();
		
			$("#btnSignUp").attr("disabled", true);
			const data = {
			    ...this.state.fields,
			    encrypted_password: this.state.fields.password,
			    //phone: this.state.fields.phone_number,
			};
			delete data.password;
			//delete data.phone_number;
			this.userService.signUp(data).then(response=>{
            	$("#btnSignUp").attr("disabled", false);
            	this.setState({error_message:''});
            	if(response.status===200) {
            		if(response.data.response==='fail') {
            			this.setState({signup_error_message:response.data.message});
            		}
            		else{
            			$('#success_message').css('display','block');
            			$('#success_message').html(response.data.message);
            			this.setState({success_message:response.data.message});
            			this.setState({success_redirect:1});
            			localStorage.setItem('show_message',1);
            			localStorage.setItem('success_message',response.data.message);
            			window.location.reload();
            			/*setTimeout(function(){
            				this.setState({success_message:''});
            				$('#success_message').css('display','none');
            			}.bind(this),5000);*/
            		}
            	}
            });
		
	}

	handleChange(field, e){
		let { fields } = this.state; 
		const { name, value } = e.target;
        const newFormData = {
            ...fields,
            [name]: value,
        };
        this.setState({fields : newFormData});
	}

	renderSignUpForm() {
		const { errors } = this.state;
		return(
			<React.Fragment>
				<form onSubmit={this.handleSubmit.bind(this)} className="sign-up-form">
					<span style={{color: "red"}}>{this.state.signup_error_message}</span>
					<div className="row">
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="first_name" className="form-label">First Name</label>
								<input type="text" className="form-control" name="first_name" placeholder="First Name" ref="first_name" id="first_name" onChange={this.handleChange.bind(this, "first_name")} defaultValue={this.state.fields["first_name"]}/>
								{errors.first_name && <div className="text-danger">{errors.first_name}</div>}
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="middle_initial" className="form-label">Middle Name</label>
								<input type="text" className="form-control" name="middle_initial" placeholder="Middle Name" id="middle_initial" ref="middle_initial" onChange={this.handleChange.bind(this, "middle_initial")} defaultValue={this.state.fields["middle_initial"]}/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="last_name" className="form-label">Last Name</label>
								<input type="text" className="form-control" name="last_name" placeholder="Last Name" ref="last_name" id="last_name" onChange={this.handleChange.bind(this, "last_name")} defaultValue={this.state.fields["last_name"]}/>
								{errors.last_name && <div className="text-danger">{errors.last_name}</div>}
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="company" className="form-label">Company Name</label>
								<input type="text" className="form-control" name="company" placeholder="Company" ref="company" id="company" onChange={this.handleChange.bind(this, "company")} defaultValue={this.state.fields["company"]}/>
								{errors.company && <div className="text-danger">{errors.company}</div>}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="address" className="form-label">Address</label>
								<input type="text" className="form-control" name="address" placeholder="Address" ref="address" id="address" onChange={this.handleChange.bind(this, "address")} defaultValue={this.state.fields["address"]}/>
								{errors.address && <div className="text-danger">{errors.address}</div>}
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="city" className="form-label">City</label>
								<input type="text" className="form-control" name="city" placeholder="City" ref="city" id="city" onChange={this.handleChange.bind(this, "city")} defaultValue={this.state.fields["city"]}/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="state" className="form-label">State</label>
								<input type="text" className="form-control" name="state" placeholder="State" ref="state" id="state" onChange={this.handleChange.bind(this, "state")} defaultValue={this.state.fields["state"]}/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="zip" className="form-label">Zip</label>
								<input type="text" className="form-control" name="zip" placeholder="Zip" ref="zip" id="zip" onChange={this.handleChange.bind(this, "zip")} defaultValue={this.state.fields["zip"]}/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="phone" className="form-label">Phone Number</label>
								<input type="text" className="form-control" name="phone" placeholder="Phone Number" ref="phone" id="phone" onChange={this.handleChange.bind(this, "phone")} defaultValue={this.state.fields["phone"]}/>
								{errors.phone && <div className="text-danger">{errors.phone}</div>}
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="cell_phone" className="form-label">Cell Number</label>
								<input type="text" className="form-control" name="cell_phone" placeholder="Cell Phone Number" ref="cell_phone" id="cell_phone" onChange={this.handleChange.bind(this, "cell_phone")} defaultValue={this.state.fields["cell_phone"]}/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="direct_phone" className="form-label">Direct Number</label>
								<input type="text" className="form-control" name="direct_phone" placeholder="Direct Phone Number" ref="direct_phone" id="direct_phone" onChange={this.handleChange.bind(this, "direct_phone")} defaultValue={this.state.fields["direct_phone"]}/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="mb-3">
								<label htmlFor="fax" className="form-label">Fax Number</label>
								<input type="text" className="form-control" name="fax" placeholder="Fax" ref="fax" id="fax" onChange={this.handleChange.bind(this, "fax")} defaultValue={this.state.fields["fax"]}/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="mb-3">
								<label htmlFor="email" className="form-label">Email</label>
								<input type="email" className="form-control" name="email" placeholder="E-mail" ref="user_email" id="user_email" onChange={this.handleChange.bind(this, "user_email")} defaultValue={this.state.fields["user_email"]}/>
								{errors.email && <span className="text-danger">{errors.email}</span>}
							</div>
						</div>
						<div className="col-md-4">
							<div className="mb-3">
								<label htmlFor="encrypted_password" className="form-label">Password</label>
								<input type="password" className="form-control" name="password" placeholder="Password" ref="encrypted_password" id="encrypted_password" onChange={this.handleChange.bind(this, "encrypted_password")} defaultValue={this.state.fields["encrypted_password"]}/>
								{errors.password && <div className="text-danger">{errors.password}</div>}
							</div>
						</div>
						<div className="col-md-4">
							<div className="mb-3">
								<label htmlFor="conf_password" className="form-label">Confirm Password</label>
								<input type="password" className="form-control" name="confirm_password" placeholder="Password Confirmation" ref="confirm_password" id="confirm_password" onChange={this.handleChange.bind(this, "conf_password")} defaultValue={this.state.fields["conf_password"]}/>
								{errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
							</div>
						</div>
					</div>
					<div className="row">	
						<div className="col-md-12 text-center">
							<div className="mb-3">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" className="custom-control-input" id="customCheck" name="example1"/>
									<label className="custom-control-label" htmlFor="customCheck">Subscribe to Newsletter</label>
								</div>
								<p><Link to={"/support"} target="_blank" >Contact Support</Link></p>
							</div>	
							<button type="submit" className="btn sign-up-btn" id="btnSignUp">Sign up</button>
						</div>
					</div>
				</form>
			</React.Fragment>
		)
	}

	render() {
		return(
			<React.Fragment>
				{this.successRedirect()}
				<main role="main" className="middle-content-area">
					<section className="sign-up-section my-3">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-sm-12 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
									<div className="default-title2">
										<div className="row">
											<div className="col-md-12">	
												<h1 className="m-0 color1">Sign Up</h1>
											</div>
										</div>
									</div>
									<p className="mt-1 underline-text">In order to create your account please provide the information below</p>
									<div className="row">
										<div className="col-md-12">
											{this.renderSignUpForm()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>	
				</main>
			</React.Fragment>
		);
	}
}

export default SignUp