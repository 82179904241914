import React from 'react';
import { Link } from 'react-router-dom';
import fullLogo from '../../images/techtop-full-logo.svg';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className="w-100 p-5 mt-5">
        <div className="container-fluid">
          <div className="row gap-5 gap-md-0 align-items-start mb-5">
            {/* Logo */}
            <div className="col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center">
              <div>
                <img
                  height="60"
                  src={fullLogo}
                  className="mb-4"
                  alt="Techtop Logo"
                />
                <p className="text-dark fw-normal d-flex align-items-center gap-2 text-nowrap">
                  <i className="fas fa-map-marker-alt"></i> Peachtree Corners,
                  GA 30071
                </p>
                <p className="text-dark fw-normal d-flex align-items-center gap-2 text-nowrap">
                  <i className="fas fa-angle-double-right"></i> 2815 Colonnades
                  Court
                </p>
              </div>
            </div>
            {/* End Logo */}

            {/* Quick Links */}
            <div className="col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center">
              <div>
                <h3 className="color1 fw-bold w-fit-content">Quick Links</h3>
                <Link
                  to="/"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-home"></i> Home
                </Link>
                <Link
                  to="/products/empty"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-search"></i> Search Products
                </Link>
                <Link
                  to="/contact"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-address-book"></i> Contact Us
                </Link>
                <Link
                  to="/track-shipment"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-truck"></i> Track your Shipment
                </Link>
                <Link
                  to="/downloads"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-download"></i> Downloads
                </Link>
              </div>
            </div>
            {/* End Quick Links */}

            {/* Contact Us */}
            <div className="col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center">
              <div>
                <h3 className="color1 fw-bold w-fit-content">Contact Us</h3>
                <Link
                  to="/support"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-1"
                >
                  <i className="fas fa-at"></i> Contact Support
                </Link>
                <a
                  href="tel:6784365540"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-phone"></i> Tel. 678-436-5540
                </a>
                <a
                  href="mailto:orders@techtopind.com"
                  className="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"
                >
                  <i className="fas fa-envelope"></i> orders@techtopind.com
                </a>
              </div>
            </div>
            {/* End Contact */}
          </div>

          <hr />

          <div className="d-flex justify-content-center">
            <p className="mt-3 text-nowrap text-muted">
              &copy; Techtop Industries, Inc. 2025
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
