import React from 'react';
import './GearSpinner.css';

const GearSpinner = ({ message = 'Loading...' }) => {
  return (
    <div className="bg spinner gap-2">
      <div className="loader"></div>
      <span className="fw-bold">{message}</span>
    </div>
  );
};

export default GearSpinner;
