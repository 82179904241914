import React, { useState, useEffect } from 'react';
import './Support.css';
import UserService from '../../services/UserService/UserService';
import { Link } from 'react-router-dom';
import GearSpinner from '../../components/GearSpinner/GearSpinner';

const Support = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sentData, setSentData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validation for form submission
  const validateForm = () => {
    const isFormValid =
      name.trim() !== '' &&
      email.trim() !== '' &&
      message.trim() !== '' &&
      emailError === '';
    setIsFormValid(isFormValid);
  };

  // Handle input change for all fields
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Update the respective field state
    if (id === 'name') {
      setName(value);
    } else if (id === 'email-input') {
      setEmail(value);
      const error = validateEmail(value)
        ? ''
        : 'Please enter a valid email address.';
      setEmailError(error);
    } else if (id === 'message') {
      setMessage(value);
    }

    // Revalidate the form whenever a field is changed
    validateForm();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const userService = new UserService();

      const response = await userService.sendSupportEmail(name, email, message);

      if (response.response === 'success') {
        setSentData({ name, email, message });
        setIsSubmitted(true);
        setErrorMessage('');
      } else {
        setErrorMessage(response.error || response.message);
        setIsSubmitted(true);
        setSentData(null);
      }
    } catch (error) {
      setErrorMessage('Failed to send message.');
      setIsSubmitted(true);
      setSentData(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Effect hook to validate the form whenever input changes
  useEffect(() => {
    validateForm();
  }, [name, email, message, emailError]);

  const handleRetry = () => {
    setIsSubmitted(false);
    setErrorMessage('');
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="App support">
      <div className="card">
        {isSubmitted ? (
          sentData ? (
            <div className="success-card">
              <h3 className="alert alert-success">
                Your message has been sent successfully!
              </h3>
              <p>
                <strong>Name:</strong> {sentData.name}
              </p>
              <p>
                <strong>Email:</strong> {sentData.email}
              </p>
              <p>
                <strong>Message:</strong> {sentData.message}
              </p>
              <Link className="go-home-button" to="/">
                Go to Home
              </Link>
            </div>
          ) : (
            <div className="error-card">
              <h3 className="alert alert-danger">
                There was an error sending your message.
              </h3>
              <p>{errorMessage}</p>
              <div className="button-container">
                <button className="retry-button" onClick={handleRetry}>
                  Try Again
                </button>
                <Link className="go-home-button" to="/">
                  Go to Home
                </Link>
              </div>
            </div>
          )
        ) : (
          <>
            <h2>Contact Support</h2>
            <p className="support-description">
              Send us your questions or comments and we will get back to you as
              soon as possible.
            </p>

            <form className="support-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email-input">Email</label>
                <input
                  id="email-input"
                  className="form-control"
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={handleInputChange}
                  required
                />
                {emailError && (
                  <small className="text-danger">{emailError}</small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="message">Issue</label>
                <textarea
                  id="message"
                  className="form-control"
                  rows={3}
                  placeholder="Describe your issue or inquiry"
                  value={message}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {isSubmitting ? (
                <GearSpinner message="Sending message..." />
              ) : (
                <button
                  className="submit-button"
                  type="submit"
                  disabled={!isFormValid || isSubmitting}
                >
                  Send Message
                </button>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Support;
