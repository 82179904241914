/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-09 18:50:28
 * @Last Modified by:   babysoft08
 * @Last Modified time: 2025-01-13 23:55:31
 */
import React from 'react';
//import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import logo from '../../images/techtop-full-logo.svg';
import HeaderService from './../../services/HeaderService/HeaderService';
import UserService from './../../services/UserService/UserService';
import TitleUnderline from '../TitleUnderline/TitleUnderline';
import TitleWithCircle from '../TitleWithCircle/TitleWithCircle';
import HeaderAccordion from '../HeaderAccordion/HeaderAccordion.js';
import './Header.style.css';
import { productHierarchy } from './categories.js';
import { urlPath, SITE_URL } from '../../constants.js';
import { Validation } from '../../validation';
import userIcon from '../../images/home-img/user.webp';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: [],
      fields: {},
      errors: {},
      error_message: '',
      user_id: '',
      first_name: '',
      last_name: '',
      company: '',
      company_logo: '',
      address: '',
      state: '',
      city: '',
      zip: '',
      phone: '',
      cell_phone: '',
      direct_phone: '',
      fax: '',
      is_login: 0,
      is_logout: 0,
      isLoading: 0,
    };
    this.renderSiteLog = this.renderSiteLog.bind(this);
    this.renderNavBar = this.renderNavBar.bind(this);
    this.renderProductMenu = this.renderProductMenuTwo.bind(this);
    // this.renderProductMenuHardCoded =
    //   this.renderProductMenuHardCoded.bind(this);
    this.loginModal = this.loginModal.bind(this);
    this.renderLoginForm = this.renderLoginForm.bind(this);
    this.headerService = new HeaderService();
    this.userService = new UserService();
    this.renderLoggedinUser = this.renderLoggedinUser.bind(this);
    this.logout = this.logout.bind(this);
    this.logoutRedirect = this.logoutRedirect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    console.log(urlPath);
  }

  handleChange(field, e) {
    let { fields } = this.state;
    const { name, value } = e.target;
    const newFormData = {
      ...fields,
      [name]: value,
    };
    this.setState({ fields: newFormData });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { fields } = this.state;
    let errors = Validation(fields, ['email', 'password']);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
      return;
    }
    console.log(errors);
    this.submitForm(e);
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ isLoading: 1 });
    $('#loginSubmit').attr('disabled', true);
    this.userService.login(this.state.fields).then((response) => {
      this.setState({ isLoading: 0 });
      $('#loginSubmit').attr('disabled', false);
      this.setState({ error_message: '' });
      if (response.status === 200) {
        console.log(response.data);
        if (response.data.response == 'fail') {
          this.setState({ error_message: response.data.message });
        } else {
          localStorage.setItem(
            'techtop_user_email',
            response.data.result[0].fields.email
          );
          localStorage.setItem('techtop_user_id', response.data.result[0].pk);
          localStorage.setItem(
            'techtop_user_first_name',
            response.data.result[0].fields.first_name
          );
          localStorage.setItem(
            'techtop_user_last_name',
            response.data.result[0].fields.last_name
          );
          localStorage.setItem(
            'techtop_user_company',
            response.data.result[0].fields.company
          );
          localStorage.setItem(
            'techtop_user_company_logo',
            response.data.result[0].fields.company_logo
          );
          localStorage.setItem(
            'techtop_user_address',
            response.data.result[0].fields.address
          );
          localStorage.setItem(
            'techtop_user_state',
            response.data.result[0].fields.state
          );
          localStorage.setItem(
            'techtop_user_city',
            response.data.result[0].fields.city
          );
          localStorage.setItem(
            'techtop_user_zip',
            response.data.result[0].fields.zip
          );
          localStorage.setItem(
            'techtop_user_phone',
            response.data.result[0].fields.phone
          );
          localStorage.setItem(
            'techtop_user_cell_phone',
            response.data.result[0].fields.cell_phone
          );
          localStorage.setItem(
            'techtop_user_direct_phone',
            response.data.result[0].fields.direct_phone
          );
          localStorage.setItem(
            'techtop_user_fax',
            response.data.result[0].fields.fax
          );
          localStorage.setItem(
            'techtop_user_multiplier',
            JSON.stringify(response.data.result[0].fields.multiplier)
          );
          localStorage.setItem('is_login', 1);
          this.setState({ user_id: response.data.result[0].pk });
          this.setState({
            first_name: response.data.result[0].fields.first_name,
          });
          this.setState({
            last_name: response.data.result[0].fields.last_name,
          });
          this.setState({
            company: response.data.result[0].fields.company,
          });
          this.setState({ is_login: 1 });
          this.setState({ is_logout: 0 });
          window.location.href = window.location.href;
          //window.location.reload();
        }
      }
    });
  }

  logoutRedirect() {
    if (this.state.is_logout === 1) {
      return <Redirect to="/" />;
    }
  }

  logout() {
    this.userService.logout().then((response) => {
      console.log(response);
      this.setState({ is_logout: 1 });
      this.setState({ is_login: 0 });
    });
  }

  componentDidMount() {
    const userId = localStorage.getItem('techtop_user_id');
    const userMultiplier = localStorage.getItem('techtop_user_multiplier');

    // Check if the user is logged in
    if (userId && userId !== '') {
      // Check if techtop_user_multiplier is null, empty, or undefined
      if (!userMultiplier || userMultiplier === '') {
        this.logout(); // Call logout method
        return; // Stop further execution
      }

      // Set user-related state if multiplier is valid
      this.setState({ user_id: userId });
      this.setState({
        first_name: localStorage.getItem('techtop_user_first_name'),
      });
      this.setState({
        last_name: localStorage.getItem('techtop_user_last_name'),
      });
      this.setState({
        company: localStorage.getItem('techtop_user_company'),
      });
      this.setState({
        company_logo:
          SITE_URL +
          'files/company_logo/' +
          localStorage.getItem('techtop_user_company_logo'),
      });
      this.setState({ phone: localStorage.getItem('techtop_user_phone') });
      this.setState({
        cell_phone: localStorage.getItem('techtop_user_cell_phone'),
      });
      this.setState({
        direct_phone: localStorage.getItem('techtop_user_direct_phone'),
      });
      this.setState({ fax: localStorage.getItem('techtop_user_fax') });
      this.setState({ address: localStorage.getItem('techtop_user_address') });
      this.setState({ state: localStorage.getItem('techtop_user_state') });
      this.setState({ city: localStorage.getItem('techtop_user_city') });
      this.setState({ zip: localStorage.getItem('techtop_user_zip') });
      this.setState({ is_login: 1 });
    }

    // Fetch menu items
    this.headerService.getCategory().then((response) => {
      if (response.status === 200) {
        this.setState({ menuItems: response.data.result });
      }
    });

    // Display success message if applicable
    const showMessage = localStorage.getItem('show_message');
    if (showMessage && showMessage === '1') {
      $('#success_message').css('display', 'block');
      $('#success_message').html(localStorage.getItem('success_message'));
    } else {
      $('#success_message').css('display', 'none');
      $('#success_message').html('');
    }

    // Clear success message after 10 seconds
    setTimeout(() => {
      localStorage.setItem('show_message', 0);
      localStorage.setItem('success_message', '');
    }, 10000);
  }

  renderSiteLog() {
    return (
      <React.Fragment>
        <a className="navbar-brand" href={'/'}>
          <img src={logo} width="200" alt="Techtopind Electric Motors" />
        </a>
      </React.Fragment>
    );
  }

  renderProductMenuTwo() {
    const nemaName = productHierarchy[0].name;
    const iecName = productHierarchy[1].name;
    const nemaCategories = productHierarchy[0].categories;
    const iecCategories = productHierarchy[1].categories;
    const accesoriesName = productHierarchy[2].name;
    const accesoriesCategories = productHierarchy[2].categories;

    return (
      <>
        <div className="header nema-col col-sm-7 col-12 px-0 d-flex flex-column justify-content-center align-items-center flex-nowrap py-3">
          <TitleUnderline linkTo={'#'} title={nemaName} />
          <div className="nema-cols d-flex row flex-nowrap align-items-start justify-content-center py-3 gap-4">
            {/* 1st sub column of nema */}
            <div className="first-sub-col header px-0 col-6 flex-shrink-1">
              {nemaCategories
                .slice(0, Math.ceil(nemaCategories.length / 2))
                .map((category) => {
                  const key = category.name.trim().replace(/\s/g, '-');
                  if (category.collapsable === true) {
                    return (
                      <HeaderAccordion
                        key={key}
                        name={category.name}
                        subItems={category.subCategories}
                        collapseId={`${key}`}
                      />
                    );
                  } else {
                    return (
                      <TitleWithCircle
                        key={key}
                        title={category.name}
                        linkTo={category.permalink}
                      />
                    );
                  }
                })}
            </div>

            {/* 2nd sub column of nema */}
            <div className="second-sub-col header first-sub-col px-0 col-6 flex-shrink-1">
              {nemaCategories
                .slice(Math.ceil(nemaCategories.length / 2))
                .map((category) => {
                  const key = category.name.trim().replace(/\s/g, '-');
                  if (category.collapsable === true) {
                    return (
                      <HeaderAccordion
                        key={key}
                        name={category.name}
                        subItems={category.subCategories}
                        collapseId={`${key}`}
                      />
                    );
                  } else {
                    return (
                      <TitleWithCircle
                        key={key}
                        title={category.name}
                        linkTo={category.permalink}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div className="header col-sm-4 col-12 d-flex flex-column gap-4 justify-content-center align-items-center px-0 py-3">
          <div className="header px-0 text-center justify-content-start align-items-center d-flex flex-column">
            <TitleUnderline linkTo={'#'} title={iecName} />
            <div className="d-flex iec-col align-items-start text-start justify-content-start row w-75 pt-3">
              {iecCategories.map((category) => {
                const key = category.name.trim().replace(/\s/g, '-');
                if (category.collapsable === true) {
                  return (
                    <HeaderAccordion
                      key={key}
                      name={category.name}
                      subItems={category.subCategories}
                      collapseId={`${key}`}
                    />
                  );
                } else {
                  return (
                    <TitleWithCircle
                      key={key}
                      title={category.name}
                      linkTo={category.permalink}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="header acce-col px-0 text-center justify-content-start align-items-center d-flex flex-column">
            <TitleUnderline linkTo={'#'} title={accesoriesName} />
            <div className="d-flex align-items-start text-start justify-content-start">
              <ul className="list-unstyled h3 text-nowrap">
                {accesoriesCategories.map((subItem, index) => {
                  return (
                    <li key={index} className="list-unstyled">
                      <a href={subItem.permalink}>{subItem.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderLoggedinUser() {
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let is_login = this.state.is_login;
    let company = this.state.company;
    if (is_login === 1) {
      return (
        <React.Fragment>
          <div className="header-right-div d-flex d-none d-md-block">
            <ul>
              <li>
                <div className="dropdown my-account-btn">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={userIcon} width="20" alt="User" /> My Account
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to={'#'}>
                        {company}
                      </Link>
                    </li>
                    <li>
                      <a className="dropdown-item" href={'/user/my-account'}>
                        Account Info
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={'/user/order-history'}>
                        Order History
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={'/user/open-orders'}>
                        Open Orders
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={'/track-shipment'}>
                        Tracking
                      </a>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to={'#'}
                        onClick={this.logout}
                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="#" onClick={(e) => e.preventDefault()}>
                  <img
                    src={this.state.company_logo}
                    width="40"
                    alt={this.state.company}
                  />
                </Link>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="header-right-div d-flex d-none d-md-block">
            <ul>
              <li>
                <Link
                  to={'#'}
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                >
                  Log In
                </Link>
              </li>
              <li>
                <a href={'/user/sign-up'}>Sign Up</a>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    }
  }

  renderNavBar() {
    const { is_login, company } = this.state;
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            {this.renderSiteLog()}

            <div className="header-right-div d-none d-md-block d-sm-block d-xl-none d-lg-none">
              {is_login ? (
                <React.Fragment>
                  <ul className="">
                    <li>
                      <div className="dropdown my-account-btn">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={userIcon} width="20" alt="User" /> My
                          Account
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link className="dropdown-item" to={'#'}>
                              {company}
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={'/user/my-account'}
                            >
                              Account Info
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={'/user/order-history'}
                            >
                              Order History
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={'/user/open-orders'}
                            >
                              Open Orders
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={'/track-shipment'}
                            >
                              Tracking
                            </a>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={'#'}
                              onClick={this.logout}
                            >
                              Log Out
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to="#" onClick={(e) => e.preventDefault()}>
                        <img
                          src={this.state.company_logo}
                          width="40"
                          alt={this.state.company}
                        />
                      </Link>
                    </li>
                  </ul>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ul>
                    <li>
                      <Link
                        to={'#'}
                        data-bs-toggle="modal"
                        data-bs-target="#loginModal"
                      >
                        Log In
                      </Link>
                    </li>
                    <li>
                      <a href="">Sign Up</a>
                    </li>
                  </ul>
                </React.Fragment>
              )}
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {' '}
              <span className="navbar-toggler-icon"></span>{' '}
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto nav-gradient">
                <li className="nav-item search">
                  <a href="/products/empty" className="nav-link search-link">
                    <i className="fas fa-search" style={{ color: '#fff' }} />
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    data-bs-toggle="dropdown"
                    to="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Products
                  </Link>
                  <div className="dropdown-menu header" id="dropdown">
                    <div className="products-dropdown d-flex flex-lg-row flex-column justify-content-center align-items-start gap-5 py-3 flex-wrap">
                      {this.renderProductMenuTwo()}
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <a href="/downloads" className="nav-link">
                    Downloads
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/contact" className="nav-link">
                    Contact us
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/track-shipment" className="nav-link">
                    Track your shipment
                  </a>
                </li>
              </ul>
              {this.renderLoggedinUser()}
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }

  renderLoginForm() {
    const { errors } = this.state;
    return (
      <React.Fragment>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className="login-form"
          id="sign_up_user"
        >
          <span style={{ color: 'red' }}>{this.state.error_message}</span>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="E-mail"
                  style={{ backgroundColor: '#FAFFBD' }}
                  id="email"
                  ref="email"
                  onChange={this.handleChange.bind(this, 'email')}
                  defaultValue={this.state.fields['email']}
                />
                {errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  style={{ backgroundColor: '#FAFFBD' }}
                  id="password"
                  ref="password"
                  onChange={this.handleChange.bind(this, 'password')}
                  defaultValue={this.state.fields['password']}
                />
                {errors.password && (
                  <div className="text-danger">{errors.password}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6 align-self-center">
              <div className="custom-control custom-checkbox">
                <input id="remember-me" name="remember-me" type="checkbox" />
                <label className="custom-control-label" htmlFor="customCheck2">
                  Remember me
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <button
                type="submit"
                className="btn login-btn"
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? 'Please Wait...' : 'Login'}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                <Link to={'/user/forgot-password'}>Forgot Your Password?</Link>
              </p>
              <p>
                <Link to={'/user/confirmation'}>
                  Didn't receive confirmation instructions?
                </Link>
              </p>
              {/* it must be <a> element instead of Link component because it will redirect and close the modal */}
              <p>
                <a href={'/support'}>Contact Support</a>
              </p>
              <p className="sign-up-text">
                <a href={'/user/sign-up'}>Sign Up</a>
              </p>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  loginModal() {
    return (
      <React.Fragment>
        <div className="modal login-modal-form fade" id="loginModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Log In</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">{this.renderLoginForm()}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { is_login, company } = this.state;
    return (
      <React.Fragment>
        {this.logoutRedirect()}
        <header className="headerSection">
          <div className="header-right-div d-block d-md-none">
            {is_login ? (
              <React.Fragment>
                <ul className="top-menu">
                  <li>
                    <div className="dropdown my-account-btn">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={userIcon} width="20" alt="User" /> My Account
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link className="dropdown-item" to={'#'}>
                            {company}
                          </Link>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={'/user/my-account'}
                          >
                            Account Info
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={'/user/order-history'}
                          >
                            Order History
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={'/user/open-orders'}
                          >
                            Open Orders
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href={'/track-shipment'}>
                            Tracking
                          </a>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={'#'}
                            onClick={this.logout}
                          >
                            Log Out
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ul className="top-menu">
                  <li>
                    <a data-bs-toggle="modal" data-bs-target="#loginModal">
                      Log In
                    </a>
                  </li>
                  <li>
                    <a href={'/user/sign-up'}>Sign Up</a>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </div>
          {this.renderNavBar()}
        </header>
        {this.loginModal()}
      </React.Fragment>
    );
  }
}

export default Header;
